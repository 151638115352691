import request from '@/utils/request'

//学生本人查询
export function getStuPersonalInfo() {
  return request({
    url: 'api/personalInfo/student',
    method: 'get',
  })
}

export function getStudentByNo(params) {
  return request({
    url: 'api/personalInfo/studentByNo',
    method: 'get',
    params
  })
}

//编辑
export function edit(data) {
  return request({
    url: 'api/personalInfo',
    method: 'post',
    data
  })
}
export default { edit, getStuPersonalInfo}
