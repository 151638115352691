<template>
  <div id="stuDetail">
    <div class="title">
      <div class="photo">
        <van-image width="78" height="106" fit="cover" :src="stuViewApi + form.stuNo" style="margin-top:35px" />
      </div>
      <div class="stuinfo">
        <div class="s-title">
          <span style="font-weight:bold">
            {{ form.stuName }}
          </span>
          <span style="margin-left:10px;font-size:15px">
            {{ form.stuNo }}
          </span>
        </div>
        <div class="s-lable">
          <svg-icon icon-class="bank-line" size="14" style="margin-right:5px" />院系: {{form.collName}}
        </div>
        <div class="s-lable">
          <van-icon name="notes-o" style="margin-right:5px" />专业: {{form.sqecName}}
        </div>
        <div class="s-lable">
          <van-icon name="home-o" style="margin-right:5px" />班级: {{form.className}}
        </div>
      </div>
    </div>
    <!-- 学籍状态 -->
    <div class="card1">
      <van-row gutter="20">
        <van-col span="8" align="center" style="margin-top:10px">
          <div class="label">学籍状态</div>
          <div class="value">{{ form.stuStatusName }}</div>
        </van-col>
        <van-col span="8" align="center" style="margin-top:10px">
          <div class="label">学籍异动</div>
          <div class="value">{{ statistics.unslCount }}次</div>
        </van-col>
        <van-col span="8" align="center" style="margin-top:10px">
          <div class="label">不及格考试</div>
          <div class="value">0次</div>
        </van-col>
      </van-row>
      <van-row gutter="20">
        <van-col span="8" align="center">
          <div class="label">奖学金</div>
          <div class="value">{{ statistics.jxjCount }}次</div>
        </van-col>
        <van-col span="8" align="center">
          <div class="label">助学金</div>
          <div class="value">{{ statistics.zxjCount }}次</div>
        </van-col>
        <van-col span="8" align="center">
          <div class="label">贷款信息</div>
          <div class="value">{{ statistics.loanCount }}次</div>
        </van-col>
      </van-row>
    </div>
    <!-- 联系方式 -->
    <div class="card2">
      <van-tabs v-model="active" line-width="15px" color="#23AB72" title-active-color="#23AB72">
        <van-tab title="联系方式">
          <div class="c2-lable"><van-icon name="phone-circle-o" color="#858CA3" size="18" style="margin-right:10px" />电话 : {{student.personalPhone }}</div>
          <div class="c2-lable"><svg-icon icon-class="wx" size="17" style="margin-right:8px" /> 微信 : {{student.wechatNo}}</div>
          <div class="c2-lable"><svg-icon icon-class="yx" size="17" style="margin-right:8px" /> 邮箱 : {{student.email }}</div>
          <div class="c2-lable"><svg-icon icon-class="ss" size="17" style="margin-right:8px" /> 宿舍 :
            <span v-if="roomData.apartment != undefined ">{{ 
              (roomData.apartment.floorName?roomData.apartment.floorName:"")
              +'-'+
              (roomData.apartment.roomCode?roomData.apartment.roomCode:"")
              +'-'+
               (roomData.apartment.bedNo?roomData.apartment.bedNo:"")
              +'床' }} </span>
            <span v-else>--</span>
          </div>
        </van-tab>
        <van-tab title="辅导员">
          <div style="display:flex">
            <div style="width:30%">
              <van-image width="73" height="99" fit="cover" :src='form.conslrStaffNo ? stuViewApi + form.conslrStaffNo : require("@/assets/imgs/fdy.png")' />
            </div>
            <div style="width:70%">
              <div style="font-w">{{form.conslrStaffName }}</div>
              <div class="c2-lable2">工号：{{form.conslrStaffNo  }}</div>
              <div class="c2-lable2">联系方式：{{ teachInfo.phoneNumber}}</div>
              <div class="c2-lable2">所在学院： {{form.collName }}</div>
            </div>
          </div>
        </van-tab>
        <van-tab title="学院副书记">
          <div style="display:flex">
            <div style="width:30%">
              <van-image width="73" height="99" fit="cover" :src='stuTecxx.staffNo ? stuViewApi + stuTecxx.staffNo : require("@/assets/imgs/fsj.png")' />
            </div>
            <div style="width:70%">
              <div style="font-w">{{stuTecxx.staffName }}</div>
              <div class="c2-lable2">工号：{{stuTecxx.staffNo  }}</div>
              <div class="c2-lable2">联系方式：{{ stuTecxx.phoneNumber}}</div>
              <div class="c2-lable2">所在学院： {{form.collName }}</div>
            </div>
          </div>
        </van-tab>
        <van-tab title="相关舍友">
          <div class="room-box">
            <div style='display: inline-block;margin: 5px 15px; text-align: center;' v-for='item in roomList' :key='item.stuNo'>
              <van-image width="73" height="99" fit="cover" :src='stuViewApi + item.stuNo' />
              <div style='text-align: center'>
                <div class='xm-text'>{{ item.stuName }}</div>
                <div class='xx-text' style='margin-top: 4px;'>{{ item.bedNo }}号床</div>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <!--  -->
    <div class="card3">
      <van-tabs v-model="active2" line-width="15px" color="#23AB72" title-active-color="#23AB72">
        <van-tab title="基本信息">
          <div class="c3-title">基本信息</div>
          <van-divider />
          <van-field v-model="form.stuNo" label="学号" readonly />
          <van-field v-model="form.stuNo" label="考生号" readonly />
          <van-field v-model="form.stuName" label="姓名" readonly />
          <van-field v-model="form.sexName" label="性别" readonly />
          <van-field v-model="student.beforeName" label="曾用名" readonly />
          <van-field v-model="form.polName " label="院系" readonly />
          <van-field v-model="form.collName " label="学号" readonly />
          <van-field v-model="form.sqecName " label="专业" readonly />
          <van-field v-model="form.className " label="班级" readonly />
          <van-field v-model="form.gradeNo" label="年级" readonly />
          <van-field v-model="student.middleSchool  " label="中学名称" readonly />
          <van-field v-model="nativePlace" label="籍贯" readonly />
          <van-field v-model="stuSource" label="生源地" readonly />
          <van-field v-model="student.singleChildFlag" label="是否独生子女" readonly>
            <template #input>
              {{student.singleChildFlag ? '是' : '否'}}
            </template>
          </van-field>
          <van-field v-model="householdClsCode" label="户口类型" readonly />
          <van-field v-model="student.personalPhone" label="电话" readonly />
          <van-field v-model=" student.email" label="电子邮箱" readonly />
          <van-field v-model="student.qqNo" label="QQ号" readonly />
          <van-field v-model="student.wechatNo" label="微信号" readonly />
          <van-field v-model="student.passportNo" label="护照号" readonly />
          <van-cell-group title="家庭成员信息">
            <van-field v-model="familyAddress" label="家庭地址" readonly />
            <van-field v-model="student.familyFullAddress" label="详细地址" readonly />
            <van-field v-model="student.familyPhone" label="家庭电话" readonly />
            <van-collapse v-model="activeNames">
              <van-collapse-item v-for="(item,index)  in  relatedList" :key="index" :title="item.relativesName+'('+item.relationship+')'" :value="item.phone" :name="index">
                <div> <span class="collapse-title">身份证号码:</span><span class="collapse-text">{{item.idCard}}</span></div>
                <div> <span class="collapse-title">健康状态:</span><span class="collapse-text">{{codeConvertLabel(item.healthCode,dict.ctry_base_health_stat)}}</span></div>
                <div> <span class="collapse-title">工作单位:</span><span class="collapse-text">{{item.employer}}</span></div>
              </van-collapse-item>
            </van-collapse>
          </van-cell-group>
        </van-tab>
        <van-tab title="学籍异动">
          <van-cell-group title="异动信息" v-if="unslList.length >0">
            <van-collapse v-model="activeNames2">
              <van-collapse-item v-for="(item,index)  in unslList" :key="index" :title="item.unslChgClsName" :value="parseTime(item.unslChgDate, '{y}-{m}-{d}') " :name=" index">
                <div> <span class="collapse-title">异动类型:</span><span class="collapse-text">{{item.unslChgClsName}}</span></div>
                <div> <span class="collapse-title">异动原因:</span><span class="collapse-text">{{item.unslChgReasonName}}</span></div>
                <div> <span class="collapse-title">原院系:</span><span class="collapse-text">{{item.originCollName}}</span></div>
                <div> <span class="collapse-title">原专业:</span><span class="collapse-text">{{item.originSpecName}}</span></div>
                <div> <span class="collapse-title">原班级:</span><span class="collapse-text">{{item.originClassName}}</span></div>
                <div> <span class="collapse-title">原年级:</span><span class="collapse-text">{{item.originGrade}}</span></div>
                <div> <span class="collapse-title">学制:</span><span class="collapse-text">{{item.originEduSystem}}</span></div>
                <div> <span class="collapse-title">现院系:</span><span class="collapse-text">{{item.curCollName}}</span></div>
                <div> <span class="collapse-title">现专业:</span><span class="collapse-text">{{item.curSpecName}}</span></div>
                <div> <span class="collapse-title">现班级:</span><span class="collapse-text">{{item.curClassName}}</span></div>
                <div> <span class="collapse-title">现年级:</span><span class="collapse-text">{{item.curGrade}}</span></div>
                <div> <span class="collapse-title">现学制:</span><span class="collapse-text">{{item.curEduSystem}}</span></div>
                <div> <span class="collapse-title">异动日期:</span><span class="collapse-text">{{ parseTime(item.unslChgDate, "{y}-{m}-{d}") }}</span></div>
              </van-collapse-item>
            </van-collapse>
          </van-cell-group>
          <van-empty v-else description="暂无数据" />
        </van-tab>
        <van-tab title="住宿信息">
          <van-cell-group title="住宿信息">
            <van-field v-model="roomData.apartment.floorName" label="楼宇" readonly />
            <van-field v-model="roomData.apartment.roomCode " label="房间号" readonly />
            <van-field v-model="roomData.apartment.bedNo " label="床位" readonly />
          </van-cell-group>
          <van-cell-group title="入住日志" v-if="roomData.logList && roomData.logList.length >0">
            <van-collapse v-model="activeNames3">
              <van-collapse-item v-for="(item,index)  in roomData.logList" :key="index" :title="item.operType" :value="item.operAt" :name=" index">
                <div> <span class="collapse-title">操作类型:</span><span class="collapse-text">{{item.operType}}</span></div>
                <div> <span class="collapse-title">操作时间:</span><span class="collapse-text">{{item.operAt}}</span></div>
                <div> <span class="collapse-title">操作人:</span><span class="collapse-text">{{item.operBy}}</span></div>
                <div> <span class="collapse-title">原校区:</span><span class="collapse-text">{{item.originCampus}}</span></div>
                <div> <span class="collapse-title">原楼宇:</span><span class="collapse-text">{{item.originFloor}}</span></div>
                <div> <span class="collapse-title">原房间:</span><span class="collapse-text">{{item.originRoom}}</span></div>
                <div> <span class="collapse-title">原床号:</span><span class="collapse-text">{{item.originBedNo}}</span></div>
                <div> <span class="collapse-title">现校区:</span><span class="collapse-text">{{item.currentCampus}}</span></div>
                <div> <span class="collapse-title">现楼宇:</span><span class="collapse-text">{{item.currentFloor}}</span></div>
                <div> <span class="collapse-title">现房间:</span><span class="collapse-text">{{item.currentRoom}}</span></div>
                <div> <span class="collapse-title">现床号:</span><span class="collapse-text">{{item.currentBedNo}}</span></div>
              </van-collapse-item>
            </van-collapse>
          </van-cell-group>
          <van-cell-group title="宿舍打分" v-if="scoreList.length >0">
            <van-collapse v-model="activeNames4">
              <van-collapse-item v-for="(item,index)  in scoreList" :key="index" :title="item.score+'分'" :value="item.floorCode" :name=" index">
                <div> <span class="collapse-title">楼宇名称:</span><span class="collapse-text"> {{  item.room.apartment.floorName }}</span></div>
                <div> <span class="collapse-title">楼宇编号:</span><span class="collapse-text">{{ item.room.apartment.floorCode }}</span></div>
                <div> <span class="collapse-title">房间号:</span><span class="collapse-text">{{ item.room.roomCode}}</span></div>
                <div> <span class="collapse-title">得分:</span><span class="collapse-text">{{item.score}}</span></div>
                <div> <span class="collapse-title">计分人:</span><span class="collapse-text">{{item.checkName}}</span></div>
                <div> <span class="collapse-title">计分时间:</span><span class="collapse-text">{{item.checkTime}}</span></div>
                <div> <span class="collapse-title">备注:</span><span class="collapse-text">{{item.remark}}</span></div>
              </van-collapse-item>
            </van-collapse>
          </van-cell-group>
        </van-tab>
        <van-tab title="奖助信息">
          <van-cell-group title="奖助信息" v-if="jzData.length >0">
            <van-collapse v-model="activeNames5">
              <van-collapse-item v-for="(item,index)  in jzData" :key="index" :title="item.year+item.teremName" :value="item.schshiName" :name=" index">
                <div> <span class="collapse-title">姓名:</span><span class="collapse-text">{{item.stuName}}</span></div>
                <div> <span class="collapse-title">学期:</span><span class="collapse-text">{{item.teremName}}</span></div>
                <div> <span class="collapse-title">奖助年度:</span><span class="collapse-text">{{item.year}}</span></div>
                <div> <span class="collapse-title">奖项名称:</span><span class="collapse-text">{{item.schshiName}}</span></div>
                <div> <span class="collapse-title">奖助类型:</span><span class="collapse-text">{{ item.typeNo == 'jxj' ? '奖学金' : '助学金'}}</span></div>
                <div> <span class="collapse-title">奖助金额:</span><span class="collapse-text">{{item.pbv}}</span></div>
              </van-collapse-item>
            </van-collapse>
          </van-cell-group>
          <van-empty v-else description="暂无数据" />
        </van-tab>
        <van-tab title="贷款信息">
          <van-cell-group title="贷款信息" v-if="loanData.length >0">
            <van-collapse v-model="activeNames6">
              <van-collapse-item v-for="(item,index)  in loanData" :key="index" :title="item.loanType == 'syd' ? '生源地贷款' : '校园地贷款'" :value="item.schoolYear" :name=" index">
                <div> <span class="collapse-title">姓名:</span><span class="collapse-text">{{item.stuName}}</span></div>
                <div> <span class="collapse-title">贷款年度:</span><span class="collapse-text">{{item.schoolYear}}</span></div>
                <div> <span class="collapse-title">贷款类型:</span><span class="collapse-text">{{item.loanType == 'syd' ? '生源地贷款' : '校园地贷款'}}</span></div>
                <div> <span class="collapse-title">贷款金额:</span><span class="collapse-text">{{item.annualLoan}}</span></div>
                <div> <span class="collapse-title">申请时间:</span><span class="collapse-text">{{ item.createTime }}</span></div>
              </van-collapse-item>
            </van-collapse>
          </van-cell-group>
          <van-empty v-else description="暂无数据" />
        </van-tab>
        <van-tab title="获奖信息">
          <van-cell-group title="获奖信息" v-if="personalAward.length >0">
            <van-collapse v-model="activeNames7">
              <van-collapse-item v-for="(item,index) in personalAward" :key="index" :title="item.awardName" :value="parseTime(item.awardAt, '{y}-{m}-{d}')" :name=" index">
                <div> <span class="collapse-title">获奖名称:</span><span class="collapse-text">{{item.awardName}}</span></div>
                <div> <span class="collapse-title">颁发单位:</span><span class="collapse-text">{{item.awardDept}}</span></div>
                <div> <span class="collapse-title">贷款类型:</span><span class="collapse-text">{{ parseTime(item.awardAt, "{y}-{m}-{d}") }}</span></div>
                <div> <span class="collapse-title">说明:</span><span class="collapse-text">{{item.remark}}</span></div>
                <file-upload v-model="item.fileId" miz="STU_AWARD_PER" biz="storage" :multiple="3" :preview="true" list-type="list" :auto-upload="true" :max-size="1">
                  <span slot="tip" class="upload-tips">
                    支持上传任意文件,文件最大1M
                  </span>
                </file-upload>
              </van-collapse-item>
            </van-collapse>
          </van-cell-group>
          <van-empty v-else description="暂无数据" />
        </van-tab>
        <van-tab title="请销假信息">
          <van-cell-group title="获奖信息" v-if="holidayList.length >0">
            <van-collapse v-model="activeNames8">
              <van-collapse-item v-for="(item,index) in holidayList" :key="index" :title="item.userName" :value="item.recDate" :name=" index">
                <div> <span class="collapse-title">姓名:</span><span class="collapse-text">{{item.userName}}</span></div>
                <div> <span class="collapse-title">外出日期:</span><span class="collapse-text">{{item.recDate}}</span></div>
                <div> <span class="collapse-title">开始时间:</span><span class="collapse-text">{{item.beginTime}}</span></div>
                <div> <span class="collapse-title">结束时间:</span><span class="collapse-text">{{item.endTime}}</span></div>
                <div> <span class="collapse-title">外出理由:</span><span class="collapse-text">{{item.other}}</span></div>
                <div> <span class="collapse-title">出发地-目的地:</span><span class="collapse-text">{{item.address}}</span></div>
                <div> <span class="collapse-title">申请时间:</span><span class="collapse-text">{{item.createTime}}</span></div>
              </van-collapse-item>
            </van-collapse>
          </van-cell-group>
          <van-empty v-else description="暂无数据" />
        </van-tab>
        <van-tab title="成绩信息">
          <van-cell-group title="考试成绩" v-if="scoreList2.length >0">
            <van-collapse v-model="activeNames9">
              <van-collapse-item v-for="(item,index) in scoreList2" :key="index" :title="item.couName" :value="item.schYear+item.termName" :name=" index">
                <div> <span class="collapse-title">姓名:</span><span class="collapse-text">{{item.stuName}}</span></div>
                <div> <span class="collapse-title">考试学年:</span><span class="collapse-text">{{item.schYear}}</span></div>
                <div> <span class="collapse-title">考试学期:</span><span class="collapse-text">{{item.termName}}</span></div>
                <div> <span class="collapse-title">课程名称:</span><span class="collapse-text">{{item.couName}}</span></div>
                <div> <span class="collapse-title">考试成绩:</span><span class="collapse-text">{{item.couMark}}</span></div>
                <div> <span class="collapse-title">考试方式:</span><span class="collapse-text">{{item.examWayName}}</span></div>
                <div> <span class="collapse-title">学分:</span><span class="collapse-text">{{item.creditScore}}</span></div>
                <div> <span class="collapse-title">考试性质:</span><span class="collapse-text">{{item.examNatureName}}</span></div>
              </van-collapse-item>
            </van-collapse>
          </van-cell-group>
          <van-empty v-else description="暂无数据" />
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>


<script>
import { getStudentByNo } from '@/api/modules/xj/stuPersonalInfo'
import { getXgStaffInfoNoPage } from '@/api/modules/xgdw/xgStaffInfo'
import { statistics } from '@/api/index/stuInfoForHome'
import { getJxjInfoAll } from '@/api/index/stuInfoForHome'
import {
  getLoanInfo,
  getRoom,
  getStuinfo,
  getUnslInfo,
} from '@/api/modules/xj/stuInfo'
import { stuViewApi } from '@/config/settings'
import {
  convertProvinceName,
} from '@/api/common/common'
import { parseTime } from "@/utils";
import { getPersonalAwardNoPage } from '@/api/modules/student/personalAward'
import { getStuScoreInfo } from '@/api/modules/student/stuScoreManage'
import { getStuHolidayInfo } from '@/api/modules/daily/stuApplyHolidayInfo'
import fileUpload from "@/components/FileUpload";

export default {
  name: "StuDetail",
  components: { fileUpload },
  dicts: ['edu_stu_adms_code', 'dlmu_hr_pd_admission_sort', 'matriculate_type', 'ctry_base_health_stat', 'ctry_comn_household_cls'],
  data() {
    return {
      stuNo: "",
      student: {},
      relatedList: [],
      form: {},
      teachInfo: {},
      stuTecxx: {},
      statistics: {},
      stuViewApi,
      active: 0,
      active2: 0,
      roomList: [],
      loanData: [],
      roomData: {
        apartment: { floorName: '暂无', roomCode: '暂无', bedNo: '暂无' },
      },
      nativePlace: null,
      stuSource: null,
      householdClsCode: "",
      familyAddress: "",
      jzData: [],
      loanData: [],
      unslList: [],
      scoreList: [],
      holidayList: [],
      activeNames: [],
      activeNames2: [],
      activeNames3: [],
      activeNames4: [],
      activeNames5: [],
      activeNames6: [],
      activeNames7: [],
      activeNames8: [],
      activeNames9: [],
      personalAward: [],
      scoreList2: []
    }
  },
  created() {
    this.stuNo = this.decrypt(this.$route.query.stu)
    this.initStuinfo(this.stuNo)
    getJxjInfoAll({ stuNo: this.stuNo }).then((res) => {
      this.jzData = res
    })
    getLoanInfo({ stuNo: this.stuNo }).then((res) => {
      this.loanData = res
    })
    getUnslInfo({ stuNo: this.stuNo }).then((res) => {
      this.unslList = res
    })
    statistics({ stuNo: this.stuNo }).then((res) => {
      this.statistics = res
    })

    getPersonalAwardNoPage({ stuNo: this.$route.query.id }).then((res) => {
      this.personalAward = []
      res.forEach((e) => {
        if (e.fileId.lenght === 0) {
          e.fileId = [0]
        }
        this.personalAward.push(e)
      })
    })

    // 学生违纪信息
    getStuScoreInfo({ stuName: this.stuNo }).then((res) => {
      this.scoreList2 = res
    })
    // 学生请销假信息
    getStuHolidayInfo({ stuName: this.stuNo }).then((res) => {
      this.holidayList = res
    })

  },
  methods: {
    parseTime,
    decrypt(str) {
      return atob(str);
    },
    codeConvertLabel(code, dicts) {
      if (code) {
        return dicts
          .filter((item) => {
            return item.value + "" === code + "";
          })
          .map((item) => {
            return item.label;
          })
          .toString();
      }
      return "";
    },
    initStuinfo(stuNo) {
      getStudentByNo({ stuNo: stuNo }).then((res) => {
        this.student = res
        this.relatedList = res.relatedList
        this.convert(res)
      })
      getStuinfo({ stuNo: stuNo }).then((res) => {
        this.form = res
        this.getTeachInfo(res.classTchStaffNo)
        this.getUnitTeachInfo(res.collNo)
      })
      getRoom({ stuNo: stuNo }).then((res) => {
        if (res.apartment != undefined) {
          this.roomList = res.list
          this.scoreList = res.scoreList
          this.roomData.logList = res.logList
          this.roomData.apartment = { ...res.apartment }
        }
      })
    },
    getTeachInfo(classTchStaffNo) {
      getXgStaffInfoNoPage({ staffNo: classTchStaffNo }).then((item) => {
        this.teachInfo = item[0]
      })
    },
    getUnitTeachInfo(unitNo) {
      getXgStaffInfoNoPage({ roleIds: [4], deptIds: [unitNo] }).then((item) => {
        if (item[0]) {
          this.stuTecxx = item[0]
        }
      })
    },
    convert(stu) {
      convertProvinceName({ code: stu.nativePlace }).then((res) => {
        this.nativePlace = res
      })
      convertProvinceName({ code: stu.stuSource }).then((res) => {
        this.stuSource = res
      })
      convertProvinceName({ code: stu.familyAddress }).then((res) => {
        this.familyAddress = res
      })
      this.dict.ctry_comn_household_cls.forEach((element) => {
        if (stu.householdClsCode === element.value) {
          this.householdClsCode = element.label
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/teacher/stuDetail.scss';

::v-deep .van-image {
  border: 1px white solid;
  border-radius: 6px;
}

.card2 {
  ::v-deep .van-tabs__content {
    padding: 10px 20px;
  }
}
::v-deep .van-tab__text {
  font-weight: bold;
  font-size: 15px !important;
}

::v-deep.van-cell {
  font-size: 15px !important;
}
.van-divider {
  width: 90%;
  margin: 0px 0 0 5% !important;
}
</style>