import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'api/evaluation/stuScore',
    method: 'post',
    data
  })
}

export function del(ids) {
  return request({
    url: 'api/evaluation/stuScore/',
    method: 'delete',
    data: ids
  })
}

export function edit(data) {
  return request({
    url: 'api/evaluation/stuScore',
    method: 'put',
    data
  })
}



export function getStuScoreInfo(params) {
  return request({
    url: 'api/stuScoreManage/byStuNo',
    method: 'get',
    params
  })
}


export function syncStuData(data) {
  return request({
    url: 'api/evaluation/stuScore/sync',
    method: 'post',
    data
  })
}


export default { add, edit, del, getStuScoreInfo, syncStuData }
