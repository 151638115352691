import request from '@/utils/request'

//首页查询学生数据
export function getStuInfoForHome(params) {
  return request({
    url: 'api/homeStu/stuinfo',
    method: 'get',
    params
  })
}


export function statistics(params) {
  return request({
    url: 'api/homeStu/statistics',
    method: 'get',
    params
  })
}


//首页查询学生数据
export function getJxjInfo(params) {
  return request({
    url: 'api/homeStu/getJxjInfo',
    method: 'get',
    params
  })
}


export function getJxjInfoAll(params) {
  return request({
    url: 'api/homeStu/getJxjInfoAll',
    method: 'get',
    params
  })
}



export default { getStuInfoForHome,statistics,getJxjInfoAll}
